import React from 'react'

function Footer() {
  return (
    <div>
        <footer class="bg-gray-900 text-gray-300 py-10">
  <div class="max-w-7xl mx-auto px-6 grid grid-cols-1 md:grid-cols-3 gap-8">
    
    <div>
      <h3 class="text-xl font-semibold text-white mb-4">About CarBikeHub</h3>
      <p class="text-sm">
        CarBikeHub is your go-to destination for the latest news, reviews, and updates about cars and bikes. We aim to fuel your passion for automobiles with reliable and engaging content.
      </p>
    </div>
    
     
    <div>
      <h3 class="text-xl font-semibold text-white mb-4">Quick Links</h3>
      <ul class="space-y-2 text-sm">
        <li><a href="#" class="hover:text-blue-400">Home</a></li>
        <li><a href="#" class="hover:text-blue-400">Trending Bikes</a></li>
        <li><a href="#" class="hover:text-blue-400">Top Cars</a></li>
        <li><a href="#" class="hover:text-blue-400">Reviews</a></li>
        <li><a href="#" class="hover:text-blue-400">Contact Us</a></li>
      </ul>
    </div>
    
     
    <div>
      <h3 class="text-xl font-semibold text-white mb-4">Contact Us</h3>
      <ul class="text-sm space-y-2">
        <li class="flex items-center">
          <img src="https://img.icons8.com/ios-filled/20/ffffff/phone.png" alt="Phone" class="mr-2" />
          +1 234 567 890
        </li>
        <li class="flex items-center">
          <img src="https://img.icons8.com/ios-filled/20/ffffff/mail.png" alt="Mail" class="mr-2" />
          info@carbikehub.com
        </li>
        <li class="flex items-center">
          <img src="https://img.icons8.com/ios-filled/20/ffffff/marker.png" alt="Location" class="mr-2" />
          123 CarBike Street, Auto City, USA
        </li>
      </ul>
      <div class="flex mt-4 space-x-4">
        <a href="#" class="hover:text-blue-400"><img src="https://img.icons8.com/ios-filled/24/ffffff/facebook-new.png" alt="Facebook" /></a>
        <a href="#" class="hover:text-blue-400"><img src="https://img.icons8.com/ios-filled/24/ffffff/instagram-new.png" alt="Instagram" /></a>
        <a href="#" class="hover:text-blue-400"><img src="https://img.icons8.com/ios-filled/24/ffffff/twitter-squared.png" alt="Twitter" /></a>
        <a href="#" class="hover:text-blue-400"><img src="https://img.icons8.com/ios-filled/24/ffffff/youtube-play.png" alt="YouTube" /></a>
      </div>
    </div>
  </div>

   
  <div class="border-t border-gray-700 mt-8 pt-4 text-center text-sm">
    <p>&copy; 2023 CarBikeHub. All Rights Reserved.</p>
  </div>
</footer>

    </div>
  )
}

export default Footer