import React, { useState } from "react";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="bg-gray-900 shadow-lg fixed top-0 left-0 w-full z-10">
      <div className="container mx-auto px-6 flex items-center justify-between h-16">
        {/* Left Section */}
        <div className="flex items-center space-x-6">
          {/* Logo */}
          <div className="flex items-center">
            <img
              src="https://img.icons8.com/ios-filled/50/ffffff/car--v1.png"
              alt="Logo"
              className="h-8 w-8 mr-2"
            />
            <span className="text-white font-bold text-xl">CarBikeHub</span>
          </div>
        </div>

        {/* Center Section */}
        <div className="hidden md:flex flex-1 max-w-md">
          <input
            type="text"
            placeholder="Search for cars, bikes..."
            className="w-full px-4 py-2 rounded-full border border-gray-600 bg-gray-800 text-gray-300 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500"
          />
        </div>

        {/* Right Section */}
        <div className="hidden md:flex items-center space-x-4">
          <button className="bg-gray-700 p-2 rounded-full hover:bg-gray-600">
            <img
              src="https://img.icons8.com/material-rounded/24/ffffff/plus.png"
              alt="Create"
              className="h-6 w-6"
            />
          </button>
          <button className="bg-gray-700 p-2 rounded-full hover:bg-gray-600">
            <img
              src="https://img.icons8.com/material-rounded/24/ffffff/bell.png"
              alt="Notifications"
              className="h-6 w-6"
            />
          </button>
          <button className="bg-gray-700 p-2 rounded-full hover:bg-gray-600">
            <img
              src="https://img.icons8.com/material-rounded/24/ffffff/user.png"
              alt="Profile"
              className="h-6 w-6"
            />
          </button>
        </div>

        {/* Mobile Hamburger Menu */}
        <div className="md:hidden">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="text-gray-300 focus:outline-none"
          >
            <img
              src="https://img.icons8.com/ios-filled/30/ffffff/menu.png"
              alt="Menu"
            />
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="md:hidden bg-gray-800">
          <div className="px-6 py-4 flex flex-col space-y-4">
            <input
              type="text"
              placeholder="Search for cars, bikes..."
              className="w-full px-4 py-2 rounded-full border border-gray-600 bg-gray-900 text-gray-300 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500"
            />
            <button className="text-gray-300 text-left px-4 py-2 rounded-md hover:bg-gray-700 hover:text-white">
              Home
            </button>
            <button className="text-gray-300 text-left px-4 py-2 rounded-md hover:bg-gray-700 hover:text-white">
              Discover
            </button>
            <button className="text-gray-300 text-left px-4 py-2 rounded-md hover:bg-gray-700 hover:text-white">
              Trending
            </button>
            <div className="flex space-x-4">
              <button className="bg-gray-700 p-2 rounded-full hover:bg-gray-600">
                <img
                  src="https://img.icons8.com/material-rounded/24/ffffff/plus.png"
                  alt="Create"
                  className="h-6 w-6"
                />
              </button>
              <button className="bg-gray-700 p-2 rounded-full hover:bg-gray-600">
                <img
                  src="https://img.icons8.com/material-rounded/24/ffffff/bell.png"
                  alt="Notifications"
                  className="h-6 w-6"
                />
              </button>
              <button className="bg-gray-700 p-2 rounded-full hover:bg-gray-600">
                <img
                  src="https://img.icons8.com/material-rounded/24/ffffff/user.png"
                  alt="Profile"
                  className="h-6 w-6"
                />
              </button>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
