import React from 'react'
import Navbar from './Car/Navbar'
import Blog from './Car/Blog'
import Footer from './Car/Footer'

function Home() {
  return (
    <>
    <div>
    <Navbar />
    <Blog />
    <Footer />
    </div>
    </>
  )
}

export default Home