import React from 'react'

function Blog() {
  return (
    <>
    <div>
    <div class="bg-white font-sans mt-20">
  <div class="max-w-6xl mx-auto p-4">
    <div class="text-center">
      <h2 class="text-3xl font-extrabold text-gray-800 inline-block relative after:absolute after:w-4/6 after:h-1 after:left-0 after:right-0 after:-bottom-4 after:mx-auto after:bg-blue-500 after:rounded-lg-full">LATEST AUTOMOBILE BLOGS</h2>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-16 max-lg:max-w-3xl max-md:max-w-md mx-auto">
      
      <div class="bg-white cursor-pointer rounded-lg overflow-hidden shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] relative group">
        <img src="https://images.unsplash.com/photo-1628763512793-c01649c7a3c9?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Blog Post 1" class="w-full h-96 object-cover" />
        <div class="p-6 absolute bottom-0 left-0 right-0 bg-blue-200 opacity-90">
          <span class="text-sm block text-gray-800 mb-2">10 FEB 2023 | BY JOHN DOE</span>
          <h3 class="text-xl font-bold text-gray-800">Top 10 Electric Cars of 2023</h3>
          <div class="h-0 overflow-hidden group-hover:h-16 group-hover:mt-4 transition-all duration-300">
            <p class="text-gray-800 text-sm">Explore the top electric cars that are revolutionizing the automobile industry in 2023 with cutting-edge technology and sustainability.</p>
          </div>
        </div>
      </div>
      
      <div class="bg-white cursor-pointer rounded-lg overflow-hidden shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] relative group">
        <img src="https://images.unsplash.com/photo-1591519924849-fc8da3e9b2ce?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Blog Post 2" class="w-full h-96 object-cover" />
        <div class="p-6 absolute bottom-0 left-0 right-0 bg-blue-200 opacity-90">
          <span class="text-sm block text-gray-800 mb-2">7 JUN 2023 | BY MARK ADAIR</span>
          <h3 class="text-xl font-bold text-gray-800">Best Adventure Bikes for 2023</h3>
          <div class="h-0 overflow-hidden group-hover:h-16 group-hover:mt-4 transition-all duration-300">
            <p class="text-gray-800 text-sm">Discover the best adventure bikes for exploring rugged terrains and long-distance travel in style and comfort.</p>
          </div>
        </div>
      </div>
      
      <div class="bg-white cursor-pointer rounded-lg overflow-hidden shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] relative group">
        <img src="https://images.unsplash.com/photo-1486326658981-ed68abe5868e?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Blog Post 3" class="w-full h-96 object-cover" />
        <div class="p-6 absolute bottom-0 left-0 right-0 bg-blue-200 opacity-90">
          <span class="text-sm block text-gray-800 mb-2">5 OCT 2023 | BY SIMON KONECKI</span>
          <h3 class="text-xl font-bold text-gray-800">Future Trends in Autonomous Cars</h3>
          <div class="h-0 overflow-hidden group-hover:h-16 group-hover:mt-4 transition-all duration-300">
            <p class="text-gray-800 text-sm">Learn about the latest trends and predictions for the autonomous car industry and how it will reshape transportation.</p>
          </div>
        </div>
      </div>
      
      <div class="bg-white cursor-pointer rounded-lg overflow-hidden shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] relative group">
        <img src="https://images.unsplash.com/photo-1558980664-2506fca6bfc2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Blog Post 4" class="w-full h-96 object-cover" />
        <div class="p-6 absolute bottom-0 left-0 right-0 bg-blue-200 opacity-90">
          <span class="text-sm block text-gray-800 mb-2">15 OCT 2023 | BY EMILY CLARK</span>
          <h3 class="text-xl font-bold text-gray-800">Top 5 Budget Bikes Under $5000</h3>
          <div class="h-0 overflow-hidden group-hover:h-16 group-hover:mt-4 transition-all duration-300">
            <p class="text-gray-800 text-sm">Find the best budget bikes that deliver excellent performance without breaking the bank in 2023.</p>
          </div>
        </div>
      </div>
      
      <div class="bg-white cursor-pointer rounded-lg overflow-hidden shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] relative group">
        <img src="https://images.unsplash.com/photo-1654157925394-4b7809721149?q=80&w=2060&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Blog Post 5" class="w-full h-96 object-cover" />
        <div class="p-6 absolute bottom-0 left-0 right-0 bg-blue-200 opacity-90">
          <span class="text-sm block text-gray-800 mb-2">20 NOV 2023 | BY LUCAS WONG</span>
          <h3 class="text-xl font-bold text-gray-800">Hybrid Cars: A Greener Tomorrow</h3>
          <div class="h-0 overflow-hidden group-hover:h-16 group-hover:mt-4 transition-all duration-300">
            <p class="text-gray-800 text-sm">Hybrid cars are bridging the gap between traditional and electric vehicles. Discover why they're the future of eco-friendly travel.</p>
          </div>
        </div>
      </div>
      
      <div class="bg-white cursor-pointer rounded-lg overflow-hidden shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] relative group">
        <img src="https://images.unsplash.com/photo-1625643074778-0417bd8eaffd?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Blog Post 6" class="w-full h-96 object-cover" />
        <div class="p-6 absolute bottom-0 left-0 right-0 bg-blue-200 opacity-90">
          <span class="text-sm block text-gray-800 mb-2">25 DEC 2023 | BY SOPHIA TAYLOR</span>
          <h3 class="text-xl font-bold text-gray-800">Electric Bikes: Revolutionizing Urban Commutes</h3>
          <div class="h-0 overflow-hidden group-hover:h-16 group-hover:mt-4 transition-all duration-300">
            <p class="text-gray-800 text-sm">Electric bikes are transforming urban commutes with efficiency and sustainability. Learn about the best options available today.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
    </>  )
}

export default Blog